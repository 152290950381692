<template>
    <div >
        <!-- listado de productos -->
        <router-view class="mt-10"></router-view>

        <!-- modal categorías -->
        <modal-categories :dialog="categoriesDialog" @closeDialog="categoriesDialog = false" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import CardList from './CardList.vue';
import ModalCategories from './ModalCategories.vue';

export default Vue.extend({
    data() {
        return {
            categoriesDialog : false,
        }
    },

    created() {
        this.$store.dispatch('updatePageTitle', {
            title: this.$t('Productos'),
            subtitle: this.$t('Administra tus productos'),
        });
        this.$store.dispatch( 'categories/getAllCategories' );
    },

    methods: {
        focusSearchInput() {
            //document.getElementById('search-icon').focus();
        },
        
        toggleRoute() {
            if( this.currentRoute === 'app-products-new' ) {
                this.$router.push({path: '/app/products'});
            } else {
                this.$router.push({path: '/app/products/new'});
            }
        },

        

    },

    computed: {
        currentRoute() {
            return this.$route.name;
        }
    },

    components: {
        CardList,
        ModalCategories,
    },

    
})
</script>